import React from 'react';
import { graphql, Link } from 'gatsby';
import _, { get } from 'lodash';
import RehypeReact from 'rehype-react';
import { AppContextProvider } from 'contexts/AppContext';
import PageLayout from 'components/layout/PageLayout';
import Button from 'components/common/Button';
import { appHomeUrl } from 'config/settings';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Hidden } from 'components/Grid';
import routePaths from 'constants/route-paths';
import { trackCTAClick } from 'utils/analytics';
import { I18nProvider, useI18n } from 'i18n';
import MoreDetails from './MoreDetails';
import Features from './Features';
import s from './MoreDetailsLayout.module.scss';

const MoreDetailsPage = ({ heroContent, eventName, page }) => {
  const { locale } = useI18n();

  const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
      features: ({ children }) => (
        <section className={s.section}>
          <Features content={children} />
        </section>
      ),
      section: ({ children }) => {
        return <section className={s.section}>{children}</section>;
      },
      list: ({ children, ...rest }) => (
        <div className={s.listContainer} {...rest}>
          {children}
        </div>
      ),
      'cta-button': ({ children }) => {
        const ctaButtonLink = get(children[0], 'props.children[0].props.href');
        const href = ctaButtonLink || `${appHomeUrl}?lang=${locale}`;

        return (
          <a href={href} target="_blank" rel="noreferrer">
            <Button className={s.ctaBtn} onClick={() => trackCTAClick(eventName)} variant="danger">
              {children}
            </Button>
          </a>
        );
      },
    },
  }).Compiler;

  return (
    <Grid>
      <Row>
        <Hidden xs sm md>
          <Col lg={1}>
            <Link to={routePaths[locale]?.home}>
              <div className={s.imgBox}>
                <ArrowBack className={s.arrowBack} />
              </div>
            </Link>
          </Col>
        </Hidden>
        <Col xs={12} lg={11}>
          <MoreDetails {...{ heroContent, eventName }}>{renderAst(page.htmlAst)}</MoreDetails>
        </Col>
      </Row>
    </Grid>
  );
};

const Template = (props) => {
  const { data } = props;
  const page = data.markdownRemark;
  const { metaTitle, metaDescription, featuredImage, bgColor, eventName, ...rest } = page.frontmatter;
  const featuredImageSizes = get(featuredImage, 'childImageSharp.sizes');

  const heroContent = React.useMemo(() => _.defaults({}, { featuredImageSizes, ...rest }), [featuredImageSizes, rest]);

  return (
    <AppContextProvider>
      <I18nProvider>
        <PageLayout {...{ bgColor, metaTitle, metaDescription, featuredImage }}>
          <MoreDetailsPage {...{ heroContent, eventName, page }} />
        </PageLayout>
      </I18nProvider>
    </AppContextProvider>
  );
};

export default Template;

export const pageQuery = graphql`
  query MoreDetailsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      htmlAst
      frontmatter {
        title
        metaTitle
        metaDescription
        subtitle
        paragraph
        btnLink
        btnLabel
        bgColor
        eventName
        featuredImageColSize
        featuredImageAltText
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 660) {
              ...GatsbyImageSharpSizes
            }
            resize(width: 800) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;
