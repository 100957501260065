import React from 'react';
import Markdown from '../../../components/common/Markdown';
import s from './Features.module.scss';

const Features = ({ content }) => (
  <div className={s.featuresContainer}>
    <div className={s.featuresContent}>{typeof content === 'string' ? <Markdown content={content} /> : content}</div>
  </div>
);

export default Features;
