import React from 'react';
import Hero from '../Hero';
import s from './MoreDetails.module.scss';

const MoreDetails = (props) => {
  const { children, heroContent, eventName } = props;
  return (
    <div className={s.container}>
      <Hero content={heroContent} eventName={eventName} />
      <div className={s.content}>{children}</div>
    </div>
  );
};

export default MoreDetails;
