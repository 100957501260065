import React from 'react';
import Title from 'components/common/Title';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Button from 'components/common/Button';
import { Hidden, Visible } from 'components/Grid';
import { trackCTAClick } from 'utils/analytics';
import s from './Hero.module.scss';

const Hero = ({ content, eventName }) => {
  const {
    title,
    subtitle,
    paragraph,
    btnLabel,
    btnLink,
    featuredImageSizes,
    featuredImageAltText,
    featuredImageColSize = 5,
  } = content;

  return (
    <Grid>
      <Hidden xs sm md xxl>
        <Row>
          <Col lg={12}>
            <Title className={s.title}>{title}</Title>
          </Col>
        </Row>
      </Hidden>
      <Row className={s.row} middle="md" between="md">
        <Col md={12} lg={featuredImageColSize ? 12 - featuredImageColSize : 7} className={s.firstCol}>
          <Visible xs sm md xxl>
            <Title className={s.title}>{title}</Title>
          </Visible>
          {featuredImageSizes ? (
            <Visible xs sm md>
              <Img fluid={featuredImageSizes} fadeIn={false} alt={featuredImageAltText || title} />
            </Visible>
          ) : null}
          <h2 className={s.subtitle}>{subtitle}</h2>
          <p className={s.paragraph}>{paragraph}</p>
          <div className={s.btnWrapper} style={!featuredImageSizes ? { textAlign: 'center' } : {}}>
            <a href={btnLink} target="_blank" rel="noreferrer">
              <Button className={s.ctaBtn} onClick={() => trackCTAClick(eventName)} variant="danger">
                {btnLabel}
              </Button>
            </a>
          </div>
        </Col>
        {featuredImageSizes ? (
          <Hidden xs sm md>
            <Col lg={featuredImageColSize || 5}>
              <Img fluid={featuredImageSizes} alt={featuredImageAltText || title} fadeIn={false} />
            </Col>
          </Hidden>
        ) : null}
      </Row>
    </Grid>
  );
};

export default Hero;
